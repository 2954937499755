import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as CProfileActions from '../actions/cProfile.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {Store} from "@ngrx/store";
import {CProfileService} from "../apis/cProfile.service";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class CProfileEffects {
  constructor(private actions$: Actions, private cProfileService: CProfileService, private store: Store,
              private toast: ToastrService
  ) {
  }

  loadCProfile$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.loadCProfile),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.loadCProfile(id).pipe(
            map(data => CProfileActions.loadCProfileSuccess({data})),
            catchError(error => of(CProfileActions.loadCProfileFailure({error})))
          );
        })
      )
    }
  );

  getHitStats$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.getHitStats),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.getHitStats(id).pipe(
            map(data => CProfileActions.getHitStatsSuccess({data})),
            catchError(error => of(CProfileActions.getHitStatsFailure({error})))
          );
        })
      )
    }
  );

  getMatchMaking$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.getMatchMaking),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.getMatchMaking(id).pipe(
            map(data => CProfileActions.getMatchMakingSuccess({active: data.active, stand_by: data.stand_by})),
            catchError(error => of(CProfileActions.getMatchMakingFailure({error})))
          );
        })
      )
    }
  );

  updateAvailability$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateAvailability),
        map((action: any) => action),
        mergeMap(({id, availability_status, availability_date}) => {
          return this.cProfileService.updateAvailability(id, availability_status, availability_date).pipe(
            map(data => CProfileActions.updateAvailabilitySuccess({data})),
            catchError(error => of(CProfileActions.updateAvailabilityFailure({error})))
          );
        })
      )
    }
  );

  updateExperienceLevel$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateExperienceLevel),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.updateExperienceLevel(id).pipe(
            map(data => CProfileActions.updateExperienceLevelSuccess({data})),
            catchError(error => of(CProfileActions.updateExperienceLevelFailure({error})))
          );
        })
      )
    }
  );

  updateRecommendationVisibility$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateRecommendationVisibility),
        map((action: any) => action),
        mergeMap(({id, recommendation_id, visibility}) => {
          return this.cProfileService.updateRecommendationVisibility(id, recommendation_id, visibility).pipe(
            map(data => CProfileActions.updateRecommendationVisibilitySuccess({data})),
            catchError(error => of(CProfileActions.updateRecommendationVisibilityFailure({error})))
          );
        })
      )
    }
  );

  emails$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.getEmails),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.getEmails(id).pipe(
            map(data => CProfileActions.getEmailsSuccess({data})),
            catchError(error => of(CProfileActions.getEmailsFailure({error})))
          );
        })
      )
    }
  );

  sendEmail$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.sendEmail),
        map((action: any) => action),
        mergeMap(({id, emailType}) => {
          return this.cProfileService.sendEmail(id, emailType).pipe(
            map(data => CProfileActions.sendEmailSuccess({data})),
            catchError(error => of(CProfileActions.sendEmailFailure({error})))
          );
        })
      )
    }
  );

  sendNoticeEmailSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CProfileActions.sendEmailSuccess),
        tap(() => {
          this.toast.success("L'email a bien été envoyé")
        }),
      );
    },
    { dispatch: false }
  );

  importLinkedin$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.importLinkedin),
        map((action: any) => action),
        mergeMap(({id, url, picture, experiences, educations,
                    certifications, localisation, recommendations, skills, english,
                    languages}) => {
          return this.cProfileService.importLinkedin(id, url, picture, experiences, educations,
            certifications, localisation, recommendations, skills, english,
            languages).pipe(
            map(data => CProfileActions.importLinkedinSuccess({data})),
            catchError(error => of(CProfileActions.importLinkedinFailure({error})))
          );
        })
      )
    }
  );
}
